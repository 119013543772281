hr {
	display: block;
	margin-top: 0.5em;
	margin-bottom: 0.5em;
	margin-left: auto;
	margin-right: auto;
	border-style: inset;
	border-width: 1.5px;
	width: 98%;
}

.resizeContainer {
	height: calc(100vh - 175px) !important;
	padding: 0px !important;
	padding-bottom: 0px !important;
	width: 100% !important;
}

.issue-content {
	padding: 49px 30px 0px 250px;
	//padding: 45px 30px 15px 250px; -- next original changed this since most panels inside the layout were above the menu and losing the header without an element-specific padding-bottom:0px added.--Kirk T. Sherer, April 20, 2021.
	//padding: 80px 30px 30px 250px; -- original size of main panel (this is the one being used on the main website.) --Kirk T. Sherer, April 17, 2020.
	min-height: calc(100vh - 45px);
}

.k-grid th,
.k-grid td {
	// padding: 2px 5px;
	// border-style: solid;
	// border-color: inherit;
	outline: 0;
	font-weight: inherit;
	text-align: left;
	overflow: auto;
	white-space: normal;
}

.issues-btn {
	color: white;
	@include box-shadow(none);
}

.white-content {
	.issues-btn {
		color: black;
	}
	.k-grid th,
	.k-grid td {
		// padding: 2px 5px;
		// border-style: solid;
		// border-color: inherit;
		outline: 0;
		font-weight: inherit;
		text-align: left;
		overflow: auto;
		white-space: normal;
		color: #212427 !important;
	}
	.k-filter-row th, .k-grid-header th.k-header {
		overflow: hidden;
	}
}

.object-contains-content {
	color: green;
}

.white-content {
	.object-contains-content {
		color: lightseagreen;
	}
}
