mat-card {
    background: none;
}

.mat-mdc-card {
	background-color: rgb(39, 41, 61) !important;
}

.white-content {
	.mat-mdc-card {
		background-color: white !important;
	}	
}