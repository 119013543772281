.mat-mdc-menu-panel {
	background-color: rgb(97, 97, 97) !important;
}

.mat-mdc-menu-item {
	color: white !important;
}

.white-content {
	.mat-mdc-menu-panel {
		background-color: white !important;
	}

	.mat-mdc-menu-item {
		color: black !important;
	}
}
